<template>
	<div>
    <form @submit.prevent="submit">
      <vs-input v-filter-input
        class="input-event-code w-full lg:w-full input-uppercase"
        v-model="dataCode"
        maxlength="6"
        icon-no-border
        icon="icon icon-arrow-right-circle"
        icon-pack="feather"
        :placeholder="$t('Enter event code')"
        icon-after="true"
        v-on:icon-click="submit()"
        >
      </vs-input>
    </form>

    <div class="centerx example-loading">
      <div
        class="fill-row-loading">
        <div
          :class="{'activeLoading': isFetching}"
          :id="[`loading-default`]"
          class="vs-con-loading__container loading-example">
          </div>
      </div>
    </div>
	</div>
</template>

<script>
import eventApi from '@/api/event';
import { getAxiosErrorMessage } from '@/lib/helper';

export default {
  components: {
  },
  props: {
    isFromRegister: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataCode: '',
      isFetching: true,
    };
  },
  computed: {
  },
  methods: {
    resetForm() {
      this.dataCode = '';
    },
    async submit() {
      this.isFetching = true;
      this.$vs.loading();
      const callback = (response) => {
        const data = response.data;
        const eventData = data.event;
        this.isFetching = false;
        this.$vs.loading.close();
        // Set event
        if (eventData) {
          localStorage.removeItem(eventData.slug);
          if (!this.isFromRegister) {
            this.$router.push(`/c/${eventData.link_code_value}?code=${this.dataCode}`);
          } else {
            this.$emit('refetchLandingPage', this.dataCode);
          }
          this.resetForm();
        }
      };
      const errorCallback = (response) => {
        this.$vs.loading.close();
        this.isFetching = false;
        const message = getAxiosErrorMessage(response);
        if (message) {
          this.$vs.notify({
            title: this.$t('Access'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        }
      };
      eventApi.getByCode(this.dataCode, callback, errorCallback);
    },
  },
  mounted() {
    localStorage.removeItem('LAST_SESSION_URL');
  },
  created() {
  },
  destroyed() {
  },
};
</script>
