<template>
	<div
		class="vx-row h-screen lg:flex w-full no-gutter"
		id="page-login"
		>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full bg-blue"
      :class="`${view === 'login' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex justify-center items-center h-full w-full">
				<div>
          <enter-event-code />

          <div class="mt-20 block lg:hidden">
            <h4 class="text-white cursor-pointer" @click="setView('login')">{{ $t('Recover Password') }} </h4>
          </div>
        </div>
			</div>
		</div>
		<div
      class="vx-col sm:w-1/1 xs:w-1/1 lg:w-1/2 h-full w-full m-4 lg:m-0"
      :class="`${view === 'code' ? 'hidden lg:block' : ''}`"
      >
			<div class="flex items-center justify-center h-full w-full">
        <div>
          <div class="lg:p-10 max-w-lg lg:max-w-md mx-auto login-wrapper w-full">
            <img src="@/assets/images/logo/koblatil.png" alt="Kobla" class="pr-5 w-1/3"/>
            <vx-card class="rounded-md">
              <div slot="no-body" class="full-page-bg-color">
                <div class="vx-row no-gutter justify-center items-center">
                  <div class="vx-col sm:w-full md:w-full lg:w-full d-theme-dark-bg">
                    <div class="p-8 mb-10 mt-10">
                      <div class="vx-card__title mb-8">
                        <h4 class="mb-4">
                          {{ $t("Recover your password") }}
                        </h4>
                        <p>
                          {{ $t("Please enter your email address and we'll send you instructions on how to reset your password.") }}
                        </p>
                      </div>
                      <vs-input
                        type="email"
                        class="w-full mb-8"
                        :label-placeholder="$t('Email')"
                        v-model="email" />
                      <vs-button type="border" to="/login" class="px-4 w-full md:w-auto">{{ $t('Back To Login') }}</vs-button>
                      <vs-button
                        class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                        :class="{ 'is-loading': isSubmitting }"
                        :disabled="email === ''"
                        @click="recover">
                        {{ $t('Recover Password') }}
                      </vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </vx-card>

            <!-- <div class="text-center mt-5">
              Haven't signed up yet? <router-link to="/register">{{ $t('Create an account') }}</router-link>
            </div> -->
          </div>

          <div class="mt-20 block lg:hidden">
            <h4 class="cursor-pointer" @click="setView('code')">{{ $t('Enter event code') }} </h4>
          </div>
        </div>
			</div>
		</div>
	</div>
</template>

<script>
import authApi from '@/api/auth';
import { getAxiosErrorMessage } from '@/lib/helper';
import EnterEventCode from '@/components/events/EnterEventCode.vue';

export default {
  components: {
    EnterEventCode,
  },
  data() {
    return {
      email: '',
      isSubmitting: false,
      windowWidth: window.innerWidth,
      view: 'code',
    };
  },
  methods: {
    recover() {
      this.isSubmitting = true;
      const email = this.email.trim();
      const params = {
        email,
        lang: this.lang,
      };
      const callback = (response) => {
        const message = response.message;
        this.$vs.notify({
          title: 'Forgot Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success',
        });
        this.isSubmitting = false;
        this.email = '';
      };
      const errorCallback = (e) => {
        const message = getAxiosErrorMessage(e);
        this.$vs.notify({
          title: 'Forgot Password',
          text: message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
        this.isSubmitting = false;
      };
      authApi.forgotPassword(params, callback, errorCallback);
    },
    setView(view) {
      this.view = view;
    },
  },
  computed: {
    lang() {
      return this.$route.query.lang || 'en';
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  },
};
</script>
<style scoped>
.auth-card{
	max-width: 470px;
}
.auth-mobile{
	min-width: 300px;
}
</style>
