<template>
	<div>
		<div class="text-white text-3xl font-base">
			<div>{{ $t('Joining as a participant') }}?</div>
			<div>{{ $t('No account needed.') }}</div>
		</div>

		<vs-row vs-type="flex" vs-justify="flex-start" class="mt-3">
			<form-enter-input-code />
		</vs-row>
	</div>
</template>

<script>
import FormEnterInputCode from '@/components/events/FormEnterInputCode.vue';

export default {
  components: {
    FormEnterInputCode,
  },
  props: {
  },
  data() {
    return {
      dataCode: '',
    };
  },
  computed: {
  },
  methods: {
  },
  mounted() {
  },
  created() {
  },
  destroyed() {
  },
};
</script>
